import Image from 'next/image';
import { useState } from 'react';
import { VideoPlayer as DatocmsVideoPlayer } from 'react-datocms';

export interface IVideo {
  muxPlaybackId: string;
  fallBackImage: string;
  placeHolderImage?: string;
  className: string;
  iconSize?: number;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  showControls?: boolean;
  showPlayButton?: boolean;
  showVolumeButton?: boolean;
}

export const VideoPlayerV2: React.FC<IVideo> = ({
  muxPlaybackId,
  fallBackImage,
  placeHolderImage = '',
  className = '',
  autoPlay = true,
  loop = true,
  muted: initMuted = true,
  playsInline = true,
  showControls = false,
  showPlayButton = false,
  showVolumeButton = false,
  iconSize = 40,
}) => {
  const [paused, setPaused] = useState(!autoPlay);
  const [muted, setMuted] = useState(initMuted);

  const playIcon = paused
    ? '/assets/icons/play.svg'
    : '/assets/icons/paused.svg';
  const soundIcon = muted
    ? '/assets/icons/volume-off.svg'
    : '/assets/icons/volume-high.svg';

  return (
    <div className={`relative ${className}`}>
      <DatocmsVideoPlayer
        loop={loop}
        muted={muted}
        paused={paused}
        autoPlay={autoPlay}
        playsInline={playsInline}
        placeholder={placeHolderImage}
        poster={fallBackImage}
        className={`relative object-cover`}
        // @ts-ignore
        style={{
          ...(showControls ? {} : { '--controls': 'none' }),
          width: '100%',
          height: '100%',
          // @ts-ignore
          '--media-object-fit': 'cover',
        }}
        data={{
          muxPlaybackId,
        }}
      />
      {showVolumeButton && (
        <Image
          width={iconSize}
          height={iconSize}
          alt="sound"
          src={soundIcon}
          onClick={() => setMuted((m) => !m)}
          className="absolute right-3 top-3 z-10"
          aria-label={muted ? 'Unmute' : 'Mute'}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setMuted((m) => !m);
            }
          }}
        />
      )}
      {showPlayButton && (
        <Image
          width={iconSize}
          height={iconSize}
          alt="play"
          src={playIcon}
          onClick={() => setPaused((p) => !p)}
          className="absolute bottom-3 right-3 z-10"
          aria-label={paused ? 'Play' : 'Pause'}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setPaused((p) => !p);
            }
          }}
        />
      )}
    </div>
  );
};
